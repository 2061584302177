<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="名称">
            <el-input v-model="form.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query">查询</el-button>
            <el-button type="primary" plain @click="handleAdd">新增</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table :data="tableData" v-loading="tableLoading" border height="100%">
          <el-table-column prop="name" label="名称" align="center"  min-width="150px"/>
          <el-table-column prop="modelId" label="模型ID" align="center" min-width="150px"/>
          <el-table-column prop="modelKey" label="模型Key" align="center" min-width="120px"/>
          <el-table-column prop="type" label="模型分类" align="center" min-width="120px"/>
          <el-table-column prop="createUser" label="创建人" align="center" min-width="120px"/>
          <el-table-column prop="createTime" label="创建时间" align="center" min-width="180px"/>
          <el-table-column prop="updateUser" label="修改人" align="center" min-width="120px"/>
          <el-table-column prop="updateTime" label="修改时间" align="center" min-width="180px"/>
          <el-table-column label="操作" align="center" width="120px" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleToFlow(scope.row)"> 编辑模型 </el-button>
              <el-button type="text" @click="handleDelete(scope.row)"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </common-layout>
    <add-modal :show.sync="showAdd" @close="closeModal" @success="addSuccess" />
  </div>
</template>
<script>
import api from '@/api/operate/flowMgt/flowModal'
import addModal from './addModal'
export default {
  components: { addModal },
  data() {
    return {
      form: {
        selectParam: {},
      },
      tableData: [],
      tableLoading: false,
      showAdd: false,
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    query() {
      let params = {
        ...this.form,
      }
      this.tableLoading = true
      api
        .getList(params)
        .then(({ data }) => {
          this.tableData = data
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleToFlow(row) {
      window.open(`http://flow-portal.mokihi.com/static/modeler/#/editor/${row.modelId}?Tenant=appstore-service`)
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        api.del({ id: row.id }).then(() => {
          this.$message.success('操作成功')
          this.query()
        })
      })
    },
    handleAdd() {
      this.showAdd = true
    },
    closeModal() {
      this.showAdd = false
    },
    addSuccess() {
      this.query()
      this.showAdd = false
    },
  },
}
</script>
