<template>
  <el-dialog title="新增流程模型" width="450px" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form label-width="70px" :model="form" :rules="rules" ref="form">
      <el-form-item label="名称" prop="name">
        <el-input placeholder="请输入" v-model="form.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="模型key" prop="modelKey">
        <el-input placeholder="请输入" v-model="form.modelKey" clearable></el-input>
      </el-form-item>
      <el-form-item label="模型分类" prop="category">
        <el-select v-model="form.category" clearable style="width: 100%">
          <el-option v-for="(item, index) in categoryList" :key="index" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="form.type" clearable style="width: 100%">
          <el-option label="应用开放"  value="APP_OPEN" />
          <el-option label="应用订阅"  value="APP_SUB" />
        </el-select>
      </el-form-item>
      <el-form-item label="描述">
        <el-input placeholder="请输入" type="textarea" v-model="form.description" clearable></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="add">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from '@/api/operate/flowMgt/flowModal'
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {},
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        modelKey: [{ required: true, message: '请输入模型key', trigger: 'blur' }],
        category: [{ required: true, message: '请选择模型分类', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
      },
      categoryList:[]
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.form = {}
      } else {
        this.$refs['form'].resetFields()
      }
    },
  },
  created() {
    this.getCategory()
  },
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    add() {
      this.$refs['form'].validate((valid) => {
        if (!valid) return
        let params = {
          ...this.form,
          modelType:0
        }
        api.add(params).then(() => {
          this.$msg.success('操作成功')
          this.$emit('success')
        })
      })
    },
    getCategory() {
      api.getCategory({type:0}).then(({data})=>{
        console.log({data})
        this.categoryList=data
      })
    }
  },
}
</script>
